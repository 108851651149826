import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./core/components/layout.component";
import "./mosaica.scss";

function Mosaica() {
	return (
		<div>
			<BrowserRouter>
				<Layout></Layout>
			</BrowserRouter>
		</div>
	);
}

//@ts-ignore
if (window.ethereum) {
	//@ts-ignore
	window.ethereum
		.request({ method: "eth_requestAccounts" })
		.then((res: any) => {
			// Return the address of the wallet
			console.log(res);
		});
}

export default Mosaica;
