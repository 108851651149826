import * as React from "react";
import { Routes, Route } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AboutUsPage from "../../pages/about-us/about-us.page";
import ActivityPage from "../../pages/activity/activity.page";
import BuyCryptoPage from "../../pages/buy-crypto/buy-crypto.page";
import LandingPage from "../../pages/landing/landing.page";
import MarketplacePage from "../../pages/marketplace/marketplace.page";
import SupportPage from "../../pages/support/support.page";
import Footer from "./footer.component";
import Header from "./header.component";

function Layout() {
	return (
		<HelmetProvider>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Mosaica Gallery</title>
				<link rel="canonical" href="http://mosaica.gallery" />
			</Helmet>
			<div className="w-full bg-yellow-100 min-h-screen">
				<Header />
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="about-us" element={<AboutUsPage />} />
					<Route path="activity" element={<ActivityPage />} />
					<Route path="buy-crypto" element={<BuyCryptoPage />} />
					<Route path="market" element={<MarketplacePage />} />
					<Route path="support" element={<SupportPage />} />
				</Routes>
				<Footer />
			</div>
		</HelmetProvider>
	);
}

export default Layout;
