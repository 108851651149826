import { Link } from "react-router-dom";

function Header() {
	return (
		<header className="w-full py-4">
			<div className="container mx-auto">
				<div className="w-full flex">
					<div className=" mx-0 w-1/3">
						<a href="/">
							<svg
								width="162"
								height="42"
								viewBox="0 0 162 42"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0 40.6294V11.2008C0 4.62262 3.13773 0.814209 9.25994 0.814209C11.8446 0.814209 13.8991 1.52437 15.4106 2.82678C16.9255 1.51223 18.9996 0.814209 21.6206 0.814209C27.7428 0.814209 30.8911 4.79879 30.8911 11.2008V40.6294H24.7106V11.2008C24.7106 8.43103 23.6045 7.04615 21.6204 7.04615C19.6481 7.04615 18.5434 8.41466 18.5304 11.1517C18.5304 11.1681 18.5304 11.1844 18.5304 11.2008V40.6294H12.3499V11.2008C12.3499 8.43103 11.2438 7.04615 9.25976 7.04615C7.27571 7.04615 6.1696 8.43103 6.1696 11.2008V40.6294H0Z"
									fill="black"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M161.001 40.6294V11.1112C161.001 4.76442 157.852 0.814209 151.729 0.814209C145.605 0.814209 142.467 4.58977 142.467 11.1112V40.6294H148.638V24.154H154.82V40.6294H161.001ZM154.82 11.1112V17.9758H148.638V11.1112C148.638 8.36533 149.744 6.9924 151.729 6.9924C153.713 6.9924 154.82 8.36533 154.82 11.1112Z"
									fill="black"
								/>
								<path
									d="M131.443 31.0188V26.2137H137.624V31.0188C137.624 37.3656 134.476 41.3159 128.352 41.3159C122.228 41.3159 119.09 37.5403 119.09 31.0188V11.1112C119.09 4.58979 122.228 0.814209 128.352 0.814209C134.476 0.814209 137.624 4.76444 137.624 11.1112V15.9165H131.443V11.1112C131.443 8.27785 130.336 6.99243 128.352 6.99243C126.367 6.99243 125.261 8.27785 125.261 11.1112V31.0188C125.261 33.8522 126.367 35.1376 128.352 35.1376C130.336 35.1376 131.443 33.8522 131.443 31.0188Z"
									fill="black"
								/>
								<path
									d="M113.327 40.6336H107.148V1.50488H113.327V40.6336Z"
									fill="black"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M101.246 11.1112V40.6296H95.0674V24.1541H88.8892V40.6296H82.7217V11.1112C82.7217 4.58979 85.8583 0.814209 91.9784 0.814209C98.0986 0.814209 101.246 4.76444 101.246 11.1112ZM88.8892 17.9759H95.0674V11.1112C95.0674 8.36536 93.9616 6.99243 91.9783 6.99243C89.9949 6.99243 88.8892 8.36536 88.8892 11.1112V17.9759Z"
									fill="black"
								/>
								<path
									d="M59.3789 31.0188C59.3789 37.8835 62.8132 41.3159 68.641 41.3159C74.4687 41.3159 77.9136 37.1969 77.9136 32.3918C77.9136 28.3076 73.9514 23.2312 72.3407 21.1676C72.0564 20.8034 71.8454 20.533 71.7424 20.3786C71.6382 20.2224 71.4231 19.924 71.1332 19.5218C69.5118 17.2725 65.5499 11.7764 65.5499 9.7383C65.5499 8.36537 66.6563 6.99243 68.6408 6.99243C70.6253 6.99243 71.7317 8.36537 71.7317 11.1112V15.9165H77.9136V11.1112C77.9136 4.24655 74.4687 0.814209 68.641 0.814209C62.8132 0.814209 59.3789 4.93302 59.3789 9.7383C59.3789 13.8799 63.4411 19.0413 65.0199 21.0474C65.2728 21.3688 65.4621 21.6093 65.5606 21.7515C65.6464 21.8752 65.8005 22.0882 66.0056 22.3712C67.5067 24.444 71.7317 30.2778 71.7317 32.3918C71.7317 33.7647 70.6253 35.1376 68.6408 35.1376C66.6563 35.1376 65.5499 33.7647 65.5499 31.0188V26.2137H59.3789V31.0188Z"
									fill="black"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M36.3838 31.0188C36.3838 37.5403 39.5222 41.3159 45.6458 41.3159C51.7695 41.3159 54.9184 37.3656 54.9184 31.0188V11.1112C54.9184 4.76444 51.7695 0.814209 45.6458 0.814209C39.5222 0.814209 36.3838 4.58979 36.3838 11.1112V31.0188ZM48.7365 31.0188C48.7365 33.7647 47.6302 35.1376 45.6457 35.1376C43.6612 35.1376 42.5548 33.7647 42.5548 31.0188V11.1112C42.5548 8.36537 43.6612 6.99243 45.6457 6.99243C47.6302 6.99243 48.7365 8.36537 48.7365 11.1112V31.0188Z"
									fill="black"
								/>
							</svg>
						</a>
					</div>
					<nav className="mx-0 w-2/3">
						<div className="flex float-right">
							<Link className="px-4" to="/about-us">
								{" "}
								About Us{" "}
							</Link>
							<Link className="px-4" to="/buy-crypto">
								{" "}
								Buy Crypto{" "}
							</Link>
							<Link className="px-4" to="/market">
								{" "}
								Marketplace{" "}
							</Link>
							<Link className="px-4" to="/activity">
								{" "}
								Activity{" "}
							</Link>
							<Link className="px-4" to="/support">
								{" "}
								Support{" "}
							</Link>
							<Link className="px-4" to="/">
								<svg
									width="19"
									height="18"
									viewBox="0 0 19 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17.1 4V2C17.1 0.897 16.2479 0 15.2 0H2.85C1.2787 0 0 1.346 0 3V15C0 17.201 1.7043 18 2.85 18H17.1C18.1479 18 19 17.103 19 16V6C19 4.897 18.1479 4 17.1 4ZM15.2 13H13.3V9H15.2V13ZM2.85 4C2.60539 3.98848 2.37443 3.87809 2.20521 3.69182C2.03599 3.50554 1.94155 3.25774 1.94155 3C1.94155 2.74226 2.03599 2.49446 2.20521 2.30818C2.37443 2.12191 2.60539 2.01152 2.85 2H15.2V4H2.85Z"
										fill="black"
									/>
								</svg>
							</Link>
							<Link to="/">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z"
										fill="black"
									/>
								</svg>
							</Link>
						</div>
					</nav>
				</div>
			</div>
		</header>
	);
}

export default Header;
