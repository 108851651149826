
interface BaseButtonProps {
    title?: string;
    click?: Function;
    children?: never[]; 
}

function BaseButton(props: BaseButtonProps) {
    const { title, click } = props;
    return (

        <button className="bg-black-900" onClick={(e) => click ? click(e) : undefined}>
            { title }
        </button>
    );
}

export default BaseButton;