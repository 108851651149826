
function AboutUsPage() {
return (
    <div className="w-full">
        <section className="container mx-auto">
            about us page
        </section>
    </div>
);
}


export default AboutUsPage;