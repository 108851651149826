
function ActivityPage() {
return (
    <div className="w-full">
        <section className="container mx-auto">
            activity page
        </section>
    </div>
);
}
    
    
export default ActivityPage;