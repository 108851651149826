import BaseButton from "../../shared/ui/buttons/btn.base";

function LandingPage() {
return (
    <div className="w-full">
        <section className="container mx-auto">
            <WelcomeBox    />
            <TrendingBox   />
            <DisclaimerBox />
            <CategoriesBox />
            <ContactsBox   />
        </section>
    </div>
);
}


function WelcomeBox() {
return (
    <div className="w-full flex py-6 mt-6">
        <div className="w-1/2 text-bold mt-6 pt-6">
            <div className="w-full">
                <h2 className="text-6xl font-bold"> 
                    Discover exclusive digital artworks 
                </h2>
                <p className="py-8"> 
                    We believe that we are on the cusp of an art revolution 
                    where barriers to entry and distribution have no limits 
                    through the “democratization” of art. 
                </p>          
            </div>
            <div className="w-full flex">
                <BaseButton 
                    title="asdfas" click={(args: any)=> console.log(args)} />
            </div>
        
        </div>
        <div className="w-1/2 mt-6 pt-6">

        </div>
    </div>
);
}


function TrendingBox() {
return (
    <div className=""></div>
);
}


function DisclaimerBox() {
return (
    <div className=""></div>
);
}


function CategoriesBox() {
return (
    <div className=""></div>
);
}


function ContactsBox() {
return (
    <div className=""></div>
);
}


export default LandingPage;