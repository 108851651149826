
function SupportPage() {
return (
    <div className="w-full">
        <section className="container mx-auto">
            support page
        </section>
    </div>
);
}


export default SupportPage;