
function MarketplacePage() {
return (
    <div className="w-full">
        <section className="container mx-auto">
            marketplace page
        </section>
    </div>
);
}


export default MarketplacePage;